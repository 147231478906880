import React from "react";
import NavItems from "./NavItems";

const LgNav = () => {
  return (
    <div className="lgNav">
      <NavItems />
    </div>
  );
};

export default LgNav;
